//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-144:_2964,_6429,_3832,_1064,_7504,_1572,_5189,_4220;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-144')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-144', "_2964,_6429,_3832,_1064,_7504,_1572,_5189,_4220");
        }
      }catch (ex) {
        console.error(ex);
      }